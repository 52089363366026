import { Link, useLocation, useSearchParams } from "react-router-dom";

import useUsers from "features/add-users/api/get-users.api";
import UsersTable from "features/add-users/components/users-table";
import Header from "features/ui/layout/headers/dashboard-header";
import { getAllSubAdminsListUrl, getAllSubAdminsUrl } from "features/subadmins/subadmins.service";
import SidebarRight from "features/ui/layout/nav/sidebar-right";
import AddMemberIcon from "assets/icons/LedgaV2 Icons/addMemberIcon";
import SearchBar from "features/shared/components/searchbar";

export default function SubAdmins() {
   const location = useLocation();
   const [query] = useSearchParams();
   const search = query.get("search");

   const {
      usersData, //
      usersDataError,
      usersDataIsLoading,
      mutateUsers,
   } = useUsers(getAllSubAdminsListUrl(search ?? ""), "V2");

   return (
      <section className="wrapper bg-white flex flex-col px-[20px] md:px-[50px] min-h-screen pb-20">
         <Header variant="OTHERS" title="Managers" settingsUrl="../settings" to={"../settings"} />
         <SearchBar pathname={location.pathname} />
         <div className="w-full min-h-screen mx-auto bg-[#FCFDFF] md:bg-[#EDF5FC] rounded-t-xl flex justify-center">
            <>
               <UsersTable
                  userType="subAdmins"
                  usersData={usersData}
                  usersDataError={usersDataError}
                  usersDataIsLoading={usersDataIsLoading}
                  mutateUsers={mutateUsers}
               />
            </>
         </div>

         <SidebarRight>
            <Link className="flex absolute bottom-24 w-full justify-center" to="../subadmins/add">
               <AddMemberIcon className="w-14 h-14" />
            </Link>
         </SidebarRight>
      </section>
   );
}
