import Form from "features/ui/forms/form";
import { Controller } from "react-hook-form";
import Select from "features/ui/forms/select";
import Input from "features/ui/forms/input";
import FormGroup from "features/ui/forms/form-group";
import Button from "features/ui/forms/button";
import { useEffect } from "react";
import useSingleGroup from "../api/group-single.api";
import groupUtils from "../groups.utils";
import ManagerSelector from "features/shared/components/manager-selector";
import useSubAdminsList from "features/subadmins/api/subadmin-list.api";

export default function GroupEditorForm({ handleSubmit, editingMode, editingId }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         title: "",
         group_type: "default",
         members: [],
         manager_access: [
            {
               admin_id: "",
               permissions: {
                  edit_members: false,
                  view_bills: false,
               },
            },
         ],
      },
   };

   return (
      <Form
         id="create-group"
         options={formOptions}
         className="self-stretch mb-24 md:mb-10"
         onSubmit={handleSubmit}
         schema={groupUtils.groupSchema}
      >
         {(hookFormMethods) => (
            <FormDetails
               hookFormMethods={hookFormMethods}
               editingMode={editingMode}
               editingId={editingId}
            />
         )}
      </Form>
   );
}

const FormDetails = ({ hookFormMethods, editingMode, editingId }) => {
   const {
      register,
      reset,
      setValue,
      control,
      formState: { errors },
   } = hookFormMethods;

   const { subAdminNames } = useSubAdminsList();
   const { groupInfo } = useSingleGroup(editingId);

   useEffect(() => {
      if (editingMode === "edit" && groupInfo) {
         reset({ title: groupInfo.title, manager_access: groupInfo.manager_access });
      }
   }, [groupInfo, editingMode, editingId, reset, setValue]);

   if (!subAdminNames) {
      return null;
   }

   return (
      <FormGroup>
         <h2 className="text-primary text-2xl font-semibold">Basic Info</h2>
         <FormGroup className="mb-6">
            <Input
               label="title"
               error={errors.title}
               isEmpty={false}
               registration={register("title")}
            />
         </FormGroup>

         {/* {editingMode !== "edit" ? (
            <FormGroup className="my-6">
               <p className="text-black-one">Group Type</p>
               <Controller
                  control={control}
                  name="group_type"
                  defaultValue="default"
                  render={({ field: { onChange, name, value } }) => (
                     <Select
                        value={value}
                        name={name}
                        label="Group Type"
                        error={errors.institution_type}
                        options={groupUtils.groupTypes}
                        handleChange={(value) => {
                           onChange(value);
                        }}
                     />
                  )}
               />
            </FormGroup>
         ) : null} */}
         <h2 className="text-primary text-2xl font-semibold">Accesibilty</h2>
         <p className="mb-6">Set access and manager to this account</p>
         <ManagerSelector
            methods={hookFormMethods}
            subAdminNames={subAdminNames}
            permissions={groupUtils.permissions}
         />
         <div className="flex justify-center">
            <Button size="WIDE" form="create-group" type="submit">
               {editingMode === "create" ? "add group" : "update group"}
            </Button>
         </div>
      </FormGroup>
   );
};
