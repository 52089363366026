import { getTransferDetails } from "utils/lib/getTransferDetails";
import { getFormattedAmount, getFormattedDate } from "./number-formatter";
import { getFormattedTime } from "./number-formatter";

export const getTransformedTransaction = (transactions) => {
   const formattedTransactions = Array.isArray(transactions)
      ? transactions
      : transactions.transactions;
   // Group transactions by their month
   const groupedTransactions = formattedTransactions.reduce((acc, transaction) => {
      const date = new Date(transaction.created_at);
      const month = `${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()}`;

      if (!acc[month]) {
         acc[month] = [];
      }

      acc[month].push(transaction);
      return acc;
   }, {});

   // Transform grouped transactions
   const transformedTransactions = Object.keys(groupedTransactions).map((month) => ({
      month,
      docs: groupedTransactions[month].map((transaction) => {
         return {
            ...transaction,
            sender: transaction?.meta?.paid_from,
            recipient: transaction?.meta?.paid_to,
            formattedDate: getFormattedDate(transaction.created_at),
            formattedTime: getFormattedTime(transaction.created_at),
            formattedAmount: getFormattedAmount(transaction.amount),
            created: transaction.created_at,
            originalAmount:
               transaction.transaction_type === "funding" && getFormattedAmount(transaction.amount),
         };
      }),
   }));

   return transformedTransactions;
};
