import Axios from "axios";
import { getToken } from "./storage";

//initialize instance of Axios with baseURL included
export const axios = Axios.create({
   baseURL: "https://backend-wuov.onrender.com/v1/",
});

//add bearer token to axios request
axios.interceptors.request.use((config) => {
   const token = getToken();
   if (token) {
      config.headers["Authorization"] = "Bearer " + token;
   }
   return config;
});

//initialize instance of Axios with baseURL included
export const axiosClient = Axios.create({
   baseURL: "https://backend-wuov.onrender.com/v2/",
});

//add bearer token to axios request
axiosClient.interceptors.request.use((config) => {
   const token = getToken();
   if (token) {
      config.headers["Authorization"] = "Bearer " + token;
   }
   return config;
});
