import { BrowserRouter } from "react-router-dom";
import { ApiRoutes } from "routes/router";
import { SWRConfig } from "swr";
import { fetcher } from "utils/lib/fetcher";
import { GlobalModalProvider } from "features/modal/global-modal.context";

function App() {
   const options = {
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      errorRetryCount: 2,
   };

   return (
      <SWRConfig value={{ fetcher: fetcher, ...options }}>
         <BrowserRouter>
            <GlobalModalProvider>
               <ApiRoutes />
            </GlobalModalProvider>
         </BrowserRouter>
      </SWRConfig>
   );
}

function Sidebar() {
   return (
      <nav
         className="
         hidden md:block 
         fixed top-0 left-0 
         w-[250px] 
         lg:w-[300px] 
         h-full 
         bg-white 
         shadow-md
         z-50
      "
      >
         {/* Sidebar navigation items */}
      </nav>
   );
}

function MobileNavbar() {
   return (
      <nav
         className="
         md:hidden 
         fixed 
         bottom-0 
         left-0 
         w-full 
         bg-white 
         shadow-top 
         z-50
      "
      >
         {/* Mobile bottom navigation items */}
      </nav>
   );
}

export default App;
