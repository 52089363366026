import { Controller } from "react-hook-form";

import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import SearchInput from "features/ui/forms/search-input";
import Spinner from "features/ui/feedbacks/spinner";
import FormGroup from "features/ui/forms/form-group";
import useBankList from "../api/getBankList.api";
import useNubanName from "../api/getNubanName.api";
import { handleBankSelect } from "../bank-details.utils";

export default function BankDetailsForm({
   bvn,
   onSubmit,
   btnText,
   modalPosition,
   hookFormMethods,
   shouldSubmit = true,
}) {
   //useForm is called in the parents so they can have
   //access to reset method
   const {
      register,
      setValue,
      setFocus,
      getValues,
      watch,
      trigger,
      control,
      clearErrors,
      formState: { errors, dirtyFields },
   } = hookFormMethods;

   const { bankNames, bankData, bankDataLoading } = useBankList(modalPosition);

   const { getNubanLoading } = useNubanName(watch, setValue, setFocus, modalPosition);

   // the bank details form is often used as a sub form of other forms, it can also be a standalone form
   const handleSubmit = async () => {
      const valid = await trigger(["bank_name", "account_number", "account_name", "bvn"]);

      if (valid) {
         const [bank_name, bank_code, account_name, account_number, bvn] = getValues([
            "bank_name",
            "bank_code",
            "account_name",
            "account_number",
            "bvn",
         ]);

         onSubmit({ bank_name, bank_code, account_name, account_number, bvn });
      }
   };

   return (
      <div className="w-full md:w-[420px] flex flex-col items-center">
         <div className="self-stretch mb-8">
            <FormGroup>
               <Controller
                  control={control}
                  name="bank_name"
                  render={({ field: { name, value } }) => (
                     <SearchInput
                        name={name}
                        label="bank name"
                        value={value}
                        error={errors.bank_name}
                        isEmpty={!dirtyFields.bank_name}
                        iconPosition="RIGHT"
                        options={bankNames}
                        registration={register("bank_name")}
                        icon={bankDataLoading && <Spinner />}
                        handleSelect={(bankName) =>
                           handleBankSelect(bankName, bankData, setValue, clearErrors)
                        }
                     />
                  )}
               />

               <Input
                  maxLength={10}
                  label="account number"
                  error={errors.account_number}
                  isEmpty={!dirtyFields.account_number}
                  registration={register("account_number")}
               />
               <Input
                  disabled
                  readOnly
                  label="account name"
                  iconPosition="RIGHT"
                  error={errors.account_name}
                  icon={getNubanLoading && <Spinner />}
                  registration={register("account_name")}
               />
               {bvn && (
                  <Input
                     label="bvn"
                     error={errors.bvn}
                     isEmpty={!dirtyFields.bvn}
                     registration={register("bvn")}
                  />
               )}
            </FormGroup>
         </div>
         {shouldSubmit && handleBankSelect ? (
            <Button size="WIDE" type="button" onClick={handleSubmit}>
               {btnText}
            </Button>
         ) : null}
      </div>
   );
}
