import useMembers from "features/add-users/api/get-members.api";
import groupUtils from "../groups.utils";

import MembersSelector from "features/shared/components/members-selector";
import FormGroup from "features/ui/forms/form-group";
import Button from "features/ui/forms/button";
import Select from "features/ui/forms/select";
import { Controller, useForm, useWatch } from "react-hook-form";
import useUpdateGroupMembers from "../api/group-members-edit.api";
import CancelIcon from "assets/icons/LedgaV2 Icons/cancelIcon";

export default function GroupMembersEditor({ editingId, existingGroupMembers }) {
   //use form is called directly here because a field in the form is needed in the
   //useUpdateGroupMembers hook invoke
   const hookFormMethods = useForm({
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         members: [],
         existingMembers: existingGroupMembers,
         update_type: "add",
         member_type: "default",
         // resolver: yupResolver(groupUtils.groupSchema)
      },
   });

   const updateType = useWatch({ control: hookFormMethods.control, name: "update_type" });
   const { handleSubmit: handleFormSubmit } = useUpdateGroupMembers(editingId, updateType);

   return (
      <form
         id="edit-group-members"
         className="self-stretch mb-24 md:mb-10"
         onSubmit={hookFormMethods.handleSubmit(handleFormSubmit)}
      >
         <FormDetails hookFormMethods={hookFormMethods} editingId={editingId} />
      </form>
   );
}

const FormDetails = ({ hookFormMethods, editingId }) => {
   const {
      control,
      setValue,
      formState: { errors },
   } = hookFormMethods;

   const updateType = useWatch({ control, name: "update_type" });
   const { membersData } = useMembers();
   const existingMembers = useWatch({ control: hookFormMethods.control, name: "existingMembers" });
   const members = useWatch({ control: hookFormMethods.control, name: "members" });
   const selectingMembers = membersData.filter((i) => !existingMembers.some((j) => j._id === i.id));

   const handleRemove = (item) => {
      hookFormMethods.setValue(
         "existingMembers",
         existingMembers.filter((i) => i._id !== item._id)
      );

      hookFormMethods.setValue("members", [...members, item._id]);
   };

   return (
      <FormGroup>
         <FormGroup className="my-6">
            <p className="text-black-one">Update Type</p>
            <Controller
               control={control}
               name="update_type"
               defaultValue="add"
               render={({ field: { onChange, name, value } }) => (
                  <Select
                     value={value}
                     name={name}
                     label="Update Type"
                     error={errors.update_type}
                     options={groupUtils.updateTypes}
                     handleChange={(value) => {
                        setValue("members", []);
                        onChange(value);
                     }}
                  />
               )}
            />
         </FormGroup>

         {updateType === "add" ? (
            <MembersSelector methods={hookFormMethods} membersData={selectingMembers} />
         ) : (
            <MembersDeselector handleRemove={handleRemove} membersData={existingMembers} />
         )}
         <div className="flex justify-center">
            <Button size="WIDE" form="edit-group-members" type="submit">
               update group
            </Button>
         </div>
      </FormGroup>
   );
};

const MembersDeselector = ({ handleRemove, membersData }) => {
   return (
      <FormGroup className="bg-white py-4 rounded-lg mb-12">
         {membersData.map((i, index) => (
            <button
               key={index}
               type="button"
               className="w-full flex justify-between p-4 hover:bg-gray-50"
               onClick={() => handleRemove(i)}
            >
               {i.name}{" "}
               <span className="text-black-one">
                  <CancelIcon />
               </span>
            </button>
         ))}
      </FormGroup>
   );
};
