import { axiosClient } from "utils/lib/axios";

export const addSubAdmin = (queryObj) => {
   return axiosClient.post("admin/add-subadmin", queryObj);
};

export const getAllSubAdminsUrl = (search = "") => `admin/subadmins?search=${search}`;

export const getAllSubAdminsListUrl = (search = "") => `admin/list-subadmins?search=${search}`;

export const getAllSubAdminsForBillsUrl = "admin/bills-subadmins";

export const updateSubAmdin = (queryObj, subAdminId) => {
   return axiosClient.patch(`admin/update-subadmin`, {
      sub_admin_id: subAdminId,
      ...queryObj,
   });
};

export const getSingleSubAdminUrl = (id) => {
   return `admin/subadmin/${id}`;
};
