import { useWatch } from "react-hook-form";

import Input from "features/ui/forms/input";
import FormGroup from "features/ui/forms/form-group";

export default function MembersSelector({ methods, membersData }) {
   const [fieldValue, searchText] = useWatch({
      control: methods.control,
      name: ["members", "searchText"],
   });

   console.log(membersData);

   const filteredData = membersData?.filter((t) => {
      if (!searchText || searchText.length < 2) {
         return membersData;
      }
      return t.name.toLowerCase().includes(searchText.toLowerCase());
   });

   if (!filteredData) {
      return null;
   }

   return (
      <section>
         <h2 className="text-xl text-black-three">Select Members </h2>
         <style jsx>{`
            ::-webkit-scrollbar {
               width: 8px;
               height: 8px;
               border-radius: 8px;
               margin-left: 2px;
            }

            ::-webkit-scrollbar-track {
               background: inherit;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 5px;
            }

            ::-webkit-scrollbar-thumb:hover {
               background: #555;
            }
         `}</style>
         <div className="bg-white p-4 mb-6 rounded-lg flex flex-col items-center max-h-[720px] overflow-y-scroll">
            <FormGroup className="w-full mb-6">
               <Input
                  label="searchText"
                  error={methods.formState.errors.searchText}
                  isEmpty={false}
                  registration={methods.register("searchText")}
                  variant="PRIMARYV3"
               />
            </FormGroup>
            {filteredData.map((item) => {
               return (
                  <label className="text-base w-full cursor-pointer" key={item._id}>
                     <input
                        className="mr-4"
                        type="checkbox"
                        value={item._id}
                        {...methods.register("members")}
                        checked={fieldValue && fieldValue.includes(item._id)}
                     />
                     <span>{item.name}</span>
                  </label>
               );
            })}
         </div>
      </section>
   );
}
