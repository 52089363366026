import React from "react";
import { useAdmin } from "features/auth/admin-context";

import BillsEditorForm from "features/bills/components/bill-editor-form";
import useCreateBill from "features/bills/api/create-bill.api";

export default function CreateBill() {
   const { handleSubmit } = useCreateBill();
   const { adminBankDetails } = useAdmin();

   // ensure bank details is available as it is used as default value in form
   if (!adminBankDetails.bankName) {
      return null;
   }

   return (
      <section className="wrapper bg-[#FCFDFF] md:bg-[#F3F3F3] min-h-screen">
         <div className="container px-4 md:px-0 self-start justify-items-start text-primary pt-10 md:pt-20 md:max-w-xl">
            <div className="w-full max-w-md mx-auto">
               <BillsEditorForm
                  adminBankDetails={adminBankDetails}
                  handleSubmit={handleSubmit}
                  editingMode="create"
               />
            </div>
         </div>
      </section>
   );
}
