import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import NumberFormat from "react-number-format";
import { useState } from "react";

import { cacDetailsSchema } from "./setup.validation";
import ImageUpload from "features/ui/forms/image-upload";
import { useCACVerification } from "./setup.api";
import { getSetupDateFormat } from "utils/lib/number-formatter";

export default function CACDetailsForm({ handleNext, setupDetails }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         company_name: "",
         company_reg_no: "",
         incorporation_date: "",
         identity_image: "",
         cac_image: "",
      },
   };

   const { verifyCac, cacVerificationLoading } = useCACVerification();

   const handleSubmit = (data, hookFormMethods) => {
      const date = getSetupDateFormat(data.incorporation_date);
      const { company_name, company_reg_no } = data;
      const onSuccess = () => {
         handleNext({
            ...data,
            incorporation_date: date,
            ...(setupDetails.corporate_account ? { date_of_birth: date } : {}),
         });
      };

      const onError = () => {
         hookFormMethods.setError("company_reg_no", {
            message: "unable to validate cac. Please ensure valid no.",
         });
      };

      verifyCac({ company_name, reg_number: company_reg_no }, onSuccess, onError);
   };

   return (
      <Form
         id="cacDetailsForm"
         options={formOptions}
         schema={cacDetailsSchema}
         onSubmit={(data, _, methods) => handleSubmit(data, methods)}
      >
         {(hookFormMethods) => (
            <FormDetails
               hookFormMethods={hookFormMethods}
               cacVerificationLoading={cacVerificationLoading}
            />
         )}
      </Form>
   );
}

export const FormDetails = ({ hookFormMethods, cacVerificationLoading }) => {
   const {
      register,
      setValue,
      clearErrors,
      Controller,
      control,
      formState: { errors, dirtyFields },
   } = hookFormMethods;

   const onSuccess = (data, fieldName) => {
      setUploading(false);
      clearErrors(fieldName);
      setValue(fieldName, data);
   };

   const [uploading, setUploading] = useState(false);

   return (
      <FormGroup className="md:w-[470px] w-[80vw] self-stretch mb-10">
         <Input
            label="company name"
            error={errors.company_name}
            isEmpty={!dirtyFields.company_name}
            registration={register("company_name")}
            variant="PRIMARYV3"
         />

         <FormGroup className="mb-6">
            <Controller
               control={control}
               name="company_reg_no"
               defaultValue=""
               render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                     customInput={Input}
                     prefix={"RC"}
                     label="company reg. no."
                     placeholder="RC"
                     name={name}
                     value={value}
                     onValueChange={(values) => {
                        onChange(values.value);
                     }}
                     error={errors.company_reg_no}
                     isEmpty={!dirtyFields.company_reg_no}
                     variant="PRIMARYV3"
                  />
               )}
            />
         </FormGroup>
         <Controller
            control={control}
            name="incorporation_date"
            defaultValue=""
            render={({ field: { onChange, name, value } }) => (
               <NumberFormat
                  customInput={Input}
                  isEmpty={value.length < 1}
                  format="##-##-####"
                  mask={["d", "d", "m", "m", "y", "y", "y", "y"]}
                  placeholder="dd-mm-yyyy"
                  error={errors.incorporation_date}
                  label="incoporation date"
                  name={name}
                  value={value}
                  onValueChange={(v) => {
                     onChange(v.formattedValue);
                  }}
                  variant="PRIMARYV3"
               />
            )}
         />

         <div className="flex md:flex-nowrap justify-between gap-10 mt-10 mb-10">
            <FormGroup className="w-full md:w-1/2">
               <ImageUpload
                  label="Copy of ID"
                  errorMessage={errors?.identity_image?.message}
                  onLoading={() => setUploading(true)}
                  onSuccess={(data) => onSuccess(data, "identity_image")}
               />
            </FormGroup>

            <FormGroup className="w-full md:w-1/2 ">
               <ImageUpload
                  label="Copy of CAC"
                  errorMessage={errors?.cac_image?.message}
                  onLoading={() => setUploading(true)}
                  onSuccess={(data) => onSuccess(data, "cac_image")}
               />
            </FormGroup>
         </div>

         <div className="flex justify-center">
            <Button size="WIDE" type="submit" form="cacDetailsForm" disabled={uploading}>
               {cacVerificationLoading ? "verifying..." : "Create"}
            </Button>
         </div>
      </FormGroup>
   );
};
