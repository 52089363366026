import { useEffect } from "react";
import useSingleBill from "../api/bill.api";
import { useWatch } from "react-hook-form";

import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import PageTitle from "features/ui/layout/headers/page-title";
import { Controller } from "react-hook-form";
import Checkbox from "features/ui/forms/check-box";
import NumberFormat from "react-number-format";

import useSubAdminsList from "features/subadmins/api/subadmin-list.api";
import ManagerSelector from "features/shared/components/manager-selector";
import GroupsSelector from "./groups-selector";
import WithdrawSignOff from "./withdraw-signoff";
// import BillTypeSelector from "./bill-type-selector";
import billUtils from "../bills.utils";

import BankDetailsForm from "features/bank-details/components/bank-details-form.component";

export default function BillsEditorForm({
   adminBankDetails,
   handleSubmit,
   editingMode,
   editingId,
}) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         title: "",
         amount: null,
         manager_access: [
            {
               admin_id: "",
               permissions: {
                  withdraw: true,
                  edit_account: true,
               },
            },
         ],
         withdrawal_permissions: {
            signOff: false,
            authorisers: [],
         },
         create_wallet: false,
         dynamic_bill: false,
         allow_part_payment: false,
         minimum_amount: null,
         addWithdrawBank: false,
         attachToGroups: false,
         groups: [],
         bank_name: adminBankDetails?.bankName,
         account_name: adminBankDetails?.accountName,
         account_number: adminBankDetails?.accountNumber,
         bank_code: adminBankDetails?.bankCode?.toString(),
      },
   };

   return (
      <Form
         id="create-savings"
         options={formOptions}
         className="self-stretch w-full max-w-md mx-auto px-4 md:px-0 mb-24 md:mb-10"
         onSubmit={handleSubmit}
         schema={billUtils.billSchema}
      >
         {(hookFormMethods) => (
            <FormDetails
               hookFormMethods={hookFormMethods}
               editingMode={editingMode}
               editingId={editingId}
            />
         )}
      </Form>
   );
}

const FormDetails = ({ hookFormMethods, editingMode, editingId }) => {
   const { billInfo } = useSingleBill(editingId);
   const { subAdminNames } = useSubAdminsList();

   const permissions = [
      { label: "Withdraw From Bill", fieldName: "withdraw" },
      { label: "Edit Account", fieldName: "edit_account" },
   ];

   const {
      register,
      control,
      reset,
      formState: { errors, dirtyFields },
   } = hookFormMethods;

   useEffect(() => {
      if (editingMode === "edit" && billInfo) {
         reset({
            title: billInfo.title,
            amount: billInfo.amount,
            manager_access: billInfo.manager_access,
            withdrawal_permissions: {
               signOff: billInfo.permissions.signOff.active,
               authorisers: billInfo.permissions.signOff.authorisers,
            },
            attachToGroups: billInfo.groups.length > 1,
            groups: billInfo.groups,
            allow_part_payment: billInfo.allow_part_payment,
            minimum_amount: billInfo.minimum_amount,
            bank_name: billInfo.payoutAccountDetails.bankName,
            bank_code: billInfo.payoutAccountDetails.bankCode,
            account_name: billInfo.payoutAccountDetails.accountName,
            account_number: billInfo.payoutAccountDetails.accountNumber,
         });
      }
   }, [billInfo, editingMode, editingId, reset]);

   const [title] = useWatch({
      name: ["title"],
      control,
   });

   if (!subAdminNames) {
      return null;
   }

   return (
      <div className="">
         <PageTitle title={editingMode === "create" ? "Create Bill" : "Edit Bill"} />
         <div className="flex flex-col w-full ">
            <p className="text-primary text-2xl">Basic Info</p>
            <p className="text-black-three">Enter basic details</p>
         </div>
         <FormGroup>
            <FormGroup className="mb-6">
               <Input
                  label="Bill Name"
                  error={errors.title}
                  isEmpty={title?.length < 1}
                  registration={register("title")}
                  variant="PRIMARYV2"
               />
            </FormGroup>
            <FormGroup>
               <Controller
                  control={control}
                  name="amount"
                  render={({ field: { onChange, name, value } }) => (
                     <NumberFormat
                        prefix={"₦ "}
                        thousandSeparator={true}
                        label="Bill Amount"
                        placeholder="₦"
                        isEmpty={dirtyFields?.amount}
                        error={errors?.amount}
                        customInput={Input}
                        name={name}
                        value={value}
                        onValueChange={(v) => {
                           onChange(Number(v.value));
                        }}
                     />
                  )}
               />
            </FormGroup>
            {/* <FormGroup>
               <BillTypeSelector methods={hookFormMethods} />
            </FormGroup> */}
            <div className="mt-[50px]">
               <p className="text-2xl">Accessibility</p>
               <p className="text-gray-400 mb-[20px]">Set access and manager to this account</p>
            </div>
            <ManagerSelector
               methods={hookFormMethods}
               subAdminNames={subAdminNames}
               permissions={permissions}
            />
            <WithdrawSignOff methods={hookFormMethods} subAdminNames={subAdminNames} />
            <BankDetails methods={hookFormMethods} />
            <GroupsSelector methods={hookFormMethods} />

            <div className="mt-[50px]">
               <p className="text-2xl">Customization</p>
               <p className="text-gray-400 mb-[20px]">Finetune account capabilities</p>
            </div>

            <Customization methods={hookFormMethods} editingMode={editingMode} />

            <div className="flex justify-center gap-5 md:gap-10">
               <Button color="TRANSPARENT" size="WIDE" type="button" to="/dashboard/bill-accounts">
                  Cancel
               </Button>
               <Button size="WIDE" form="create-savings" type="submit">
                  Create
               </Button>
            </div>
         </FormGroup>
      </div>
   );
};

const Customization = ({ methods, editingMode }) => {
   let customizations = [{ name: "allow_part_payment", label: "Allow Part Payment" }];

   if (editingMode === "create") {
      customizations = [
         ...customizations,
         { name: "create_wallet", label: "Create Wallet" },
         { name: "dynamic_bill", label: "Dynamic Bill" },
      ];
   }
   const allowPartPayment = useWatch({ control: methods.control, name: "allow_part_payment" });

   return (
      <div className="rounded-md bg-white flex flex-col gap-3 p-4 justify-center my-[20px] mb-[30px]">
         {customizations.map((c) => (
            <FormGroup className="mx-1" key={c.name}>
               <Controller
                  control={methods.control}
                  name={c.name}
                  render={({ field: { onChange, name, value } }) => (
                     <Checkbox
                        variant="SECONDARY"
                        name={name}
                        value={value}
                        label={c.label}
                        handleChange={(v) => {
                           if (c.name === "allow_part_payment" && v.target.value) {
                              methods.setValue("minimum_amount", null);
                           }
                           onChange(v);
                        }}
                     />
                  )}
               />

               {c.name === "allow_part_payment" && allowPartPayment ? (
                  <Controller
                     control={methods.control}
                     name="minimum_amount"
                     render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                           prefix={"₦ "}
                           thousandSeparator={true}
                           label="Minimum Amount"
                           placeholder="₦"
                           isEmpty={!methods?.formState?.dirtyFields?.minimum_amount}
                           error={methods.formState?.errors?.amount}
                           customInput={Input}
                           name={name}
                           value={value}
                           onValueChange={(v) => {
                              onChange(Number(v.value));
                           }}
                        />
                     )}
                  />
               ) : null}
            </FormGroup>
         ))}
      </div>
   );
};

const BankDetails = ({ methods }) => {
   return (
      <section>
         <h2 className="text-xl text-black-three">Withdraw Bank</h2>
         <p className="text-gray-400">
            Attach bank account for processing withdrawals from this account.
         </p>
         <div className="bg-white p-4 w-full mb-6 rounded-lg flex flex-col items-center">
            <BankDetailsForm btnText="next" hookFormMethods={methods} shouldSubmit={false} />
         </div>
      </section>
   );
};
